var exports = {};

function tryStringify(o) {
  try {
    return JSON.stringify(o);
  } catch (e) {
    return "\"[Circular]\"";
  }
}

exports = format;

function format(f, args, opts) {
  var ss = opts && opts.stringify || tryStringify;
  var offset = 1;

  if (typeof f === "object" && f !== null) {
    var len = args.length + offset;
    if (len === 1) return f;
    var objects = new Array(len);
    objects[0] = ss(f);

    for (var index = 1; index < len; index++) {
      objects[index] = ss(args[index]);
    }

    return objects.join(" ");
  }

  if (typeof f !== "string") {
    return f;
  }

  var argLen = args.length;
  if (argLen === 0) return f;
  var str = "";
  var a = 1 - offset;
  var lastPos = -1;
  var flen = f && f.length || 0;

  for (var i = 0; i < flen;) {
    if (f.charCodeAt(i) === 37 && i + 1 < flen) {
      lastPos = lastPos > -1 ? lastPos : 0;

      switch (f.charCodeAt(i + 1)) {
        case 100: // 'd'

        case 102:
          // 'f'
          if (a >= argLen) break;
          if (args[a] == null) break;
          if (lastPos < i) str += f.slice(lastPos, i);
          str += Number(args[a]);
          lastPos = i + 2;
          i++;
          break;

        case 105:
          // 'i'
          if (a >= argLen) break;
          if (args[a] == null) break;
          if (lastPos < i) str += f.slice(lastPos, i);
          str += Math.floor(Number(args[a]));
          lastPos = i + 2;
          i++;
          break;

        case 79: // 'O'

        case 111: // 'o'

        case 106:
          // 'j'
          if (a >= argLen) break;
          if (args[a] === undefined) break;
          if (lastPos < i) str += f.slice(lastPos, i);
          var type = typeof args[a];

          if (type === "string") {
            str += "'" + args[a] + "'";
            lastPos = i + 2;
            i++;
            break;
          }

          if (type === "function") {
            str += args[a].name || "<anonymous>";
            lastPos = i + 2;
            i++;
            break;
          }

          str += ss(args[a]);
          lastPos = i + 2;
          i++;
          break;

        case 115:
          // 's'
          if (a >= argLen) break;
          if (lastPos < i) str += f.slice(lastPos, i);
          str += String(args[a]);
          lastPos = i + 2;
          i++;
          break;

        case 37:
          // '%'
          if (lastPos < i) str += f.slice(lastPos, i);
          str += "%";
          lastPos = i + 2;
          i++;
          a--;
          break;
      }

      ++a;
    }

    ++i;
  }

  if (lastPos === -1) return f;else if (lastPos < flen) {
    str += f.slice(lastPos);
  }
  return str;
}

export default exports;